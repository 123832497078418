import React from 'react'
import PageCreator from '../components/pages/Creator/Creator'
import Layout from '../Layouts/en'

const creatorEn = ({ location }) => {
  return (
    <Layout location={location}>
      <PageCreator />
    </Layout>
  )
}

export default creatorEn
